import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Container } from '../blocks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Overview`}</h1>
    <p>{`Every NFTs are fundamentally Bytecodes which are Binary. Since NFTs bear an intrinsic value bringing magic money to the Binary. A perfect framework to streamline interconnectedness and interdependence between Digital assets. We may find interdependence to be an important framework for building a collaborative Metaverse.
We continue to face difficulties in dealing with aspects of the Metaverse  that are loosely coupled digital assets, and where there is less interdependence between the elements and assets that are involved.
We do have the best set of tools and frameworks to co-create the Metaverse, however, we still lack a framework for linking all these digital assets across numerous blockchains, something like a Meta Wide Web. All information is stored and processed On-chain preserving credibility and ownership. `}</p>
    <h1>{`Introduction`}</h1>
    <p>{`All these 10k pfps, arts, graphics, photographs, avatars, ens, and all these Meta data propagate together and form the Metaverse. Every individual nfts contribute to a great vision, an intangible meta vision.
Every price of information becomes an asset over time, representing the fair intrinsic value of the information. We have every tech that exists except an interconnectedness between Assets.
Our mission is to map all NFTs and Digital assets in a single platform, providing interconnectedness and interdependence between every asset. So that we can respond and adapt to circumstances. We can change. We can work toward our goals by gathering the conditions needed to accomplish them. Understanding how this fundamental principle works in our lives enables us to consciously reorient our lives and change the world itself.
When the entire Metaverse is tightly interdependent with each digital asset, the microeconomic responses can easily escalate into macroeconomic contagion. Every asset will be mutually dependent on each other and be bridged together in an Oracle.`}</p>
    <h1>{`Binary Bodi`}</h1>
    <p>{`An experiment to Awaken the Metaverse`}</p>
    <h1>{`What is Binary Bodi?`}</h1>
    <p>{`Binary Bodi is an experimental Web3 platform that empowers creators, collectors, authors, and brands to participate in the Metaverse Press Syndicate (MPS) to share Meta Culture stories and deliver unique experiences while getting monetized for your effort. A first-of-kind Meta Press featuring only NFTs . A kind of bridge between digital assets, not any wormhole bridge; a framework to utilize nfts in a safe and niche manner, while preserving the ownership and utility.`}</p>
    <h1>{`Bodi Oracle`}</h1>
    <p>{`Interconnecting NFTs, Cultures, and Experiences.`}</p>
    <h1>{`Bodi`}</h1>
    <p>{`The primordial avatar of our eco chamber, Bodi represents the awakened one. Relies on everyone, Bodi makes your life less miserable and more beautiful.`}</p>
    <h1>{`Syndicate Pass`}</h1>
    <p>{`Issued to filter out contents, Mandatory for Creators, Artists, and Authors to hold Syndicate passes to participate in the MPS.`}</p>
    <h1>{`Syndicate Members`}</h1>
    <p>{`Holders of the Bodi, Early adopters, and foundational pillars of MSP and the Oracle. Syndicate Members can collaborate and contribute to releases and can earn % of royalties for their submissions.`}</p>
    <h1>{`The Oracle of Binary Bodi`}</h1>
    <p>{`BinaryBodi Genesis edition The Oracle of BinaryBodi features comprehensive coverage of Perennial Philosophy, Spirituality, Psychoanalysis, Meta counter-culture, Memes, and more.`}</p>
    <h1>{`Metaverse Press Syndicate`}</h1>
    <p>{`Metaverse Press Syndicate, or Metaverse Feature Syndicate, is DAO that oversea the news trends, market trends, and other media special writing and artwork, often written by reputed and talented journalists or eminent generative projects or well-known Meta artists that cannot be classified as spot coverage of the news. Its fundamental service is to spread the cost of expensive features among as many media as possible. Press syndicates sell the exclusive rights to a feature to one subscriber in each territory, in contrast to the wire news services which offer their reports to all papers in a given area. Some syndicates specialize in such entertainment features as comic strips, cartoons, columns of oddities or humor, and serialized novels. Typical syndicated features are columns of advice on child rearing, health, running a household, gardening, and such games as the bridge.`}</p>
    <h1>{`Multi Stakeholders`}</h1>
    <p>{`Every writer, photographer, and graphic artist, generative projects syndicate their materials. With strong resources, anyone can syndicate their coverage, including news, to papers outside their communities.
The use of many illustrations, color magazine sections, and glaring headlines; its sensational articles on spirituality and pseudoscientific topics; its bellicosity in perennial philosophy; and its transcendental wisdom.
Degen gathers, Shitposters, Gm cults,  writes, and distributes news from around the metaverse to the world to newspapers, Posters, Features, Periodicals, Radio and television broadcasters, media agencies, community projects, and other users. MPS does not generally publish news itself but supplies news to its subscribers, who, by sharing costs, obtain services they could not otherwise afford.
Featuring routine coverage of news about the market trends, TA, rug pulls, confessions, CT drama, horrors and satires, and the like. MPS will be extended the area of such coverage by gathering and distributing stock-market quotations, sports results, and election reports. MPS include news interpretation, special columns, news photographs, audiotape recordings for radio broadcast, and often videotape or motion-picture film for television news reports.
The ability to transmit news rapidly increasing in this web3 era. Crypto Twitter makes possible fast automatic transmission of news messages linked to all metaverse areas. Picture transmission by memes and high-quality criticism will be included.
MPs are for mass communication whereby information, opinion, advocacy, propaganda, advertising, artwork, entertainment, and other forms of expression are conveyed to a very large audience. MPS is also used to refer collectively to types of public or private organizations that produce or disseminate particular forms of expression through such modes, including newspapers and wire services, periodicals, book publishers, libraries, radio and television networks, movie studios, and record companies. `}</p>
    <h1>{`TDLR`}</h1>
    <p>{`We create an Oracle for bridging and linking all existing digital assets.
We map all NFTs, ENS, and other virtual assets in a standalone platform.
We create, curate, and collect the Context for The Oracle of Binary Bodi.
The Oracle of Binary Bodi is the genesis edition by Metaverse Press Syndicate.
Only Syndicate Members can contribute or submit or License their assets.
Membership will be granted by purchasing Syndicate Pass.
Only Syndicate Pass holders can participate in the content syndication of the Oracle.
Artists, Syndicate members, and Contributors get % of royalties.
Communities can co-create different Sub-Oracles according to their personalized need.
We propose MPS as a movement towards forming a SyndicateDAO with SBT governance.`}</p>
    <h1>{`Features`}</h1>
    <p>{`Can attach or symlink every digital asset.
Onchain collaboration and co-creation.
Censorship resistance Press syndicate
Interconnected NFTs
Making NFTs self-sustainable.
Magazines, Stories, Cartoons, Market trends and Analysis, Degen trades and Rug pulls, Moon shots and Gem swaps, Romance and CT trauma, weed and gm cult, and of course everything will be NFTs.`}</p>
    <p>{`Artists can submit their creations according to the context.
Contributors can provide Media submissions for any Context.
Authors can publish relevant text media for the Context
Collaborators feel unity when every collaboration happens over Onchain.`}</p>
    <p>{`Every stakeholder is required to become a syndicate member to participate in the Oracle and MPS. Syndicate members can suggest submissions from various participants.
The Oracle of Binary Bodi will be completely funded by MPS treasury which overseas syndicate pass liquidity. 90% of the Syndicate Pass sales volume will be diverted to the MPS Treasury Wallet. Facilitating the purchases of assets for Oracle and maintaining the royalty and loyalty rewards for all stakeholders.`}</p>
    <h1>{`How does MPS make money?`}</h1>
    <p>{`collect money in perpetuity via % of royalties.
do pay (or free) commission work for holders `}</p>
    <h1>{`In conclusion`}</h1>
    <p>{`This is a peak experiment Mapping all NFTs and digital assets across the web3 to streamline a standalone framework to bridge and link multiple assets across various chains.
A framework that facilitates collaboration, development, moderation, and co-creation between various projects, arts, and media. We believe NFTs and digital assets continue to evolve and exist in an inter-connected and interdependent manner, So as can shape and build towards a better Desoc. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      